<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CCard>
			<CCardBody>
				<TheTableHeader
					:header="'FORMULIR PENGATURAN'"
					:subHeader="'DATA KARTU AKSES DATA CENTER DI LINTASARTA.'"
				/>
			</CCardBody>
		</CCard>

		<CRow>
			<CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>Form {{id > 0 ? 'Perubahan' : 'Penambahan'}}</strong> Data Kartu Akses.
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="6">
								<CInput
									type="text"
									description=""
									label="Nomor Kartu Akses"
									placeholder="Masukkan nomor kartu.."
									v-model="card_number"
									required
								/>
							</CCol>
							<CCol sm="6">
								<CSelect
									v-model="company_id"
									name="company_id"
									label="Perusahaan"
									:options="companies"
									@change="companyChange($event)"
								/> 
								<!-- <CInput
									type="text"
									description=""
									label="Pemegang Kartu Akses"
									placeholder="Masukkan pemegang kartu.."
									v-model="card_holder"
									required
								/> -->
							</CCol>
							<CCol sm="6">
								<CSelect
									:value.sync="card_holder"
									name="card_holder"
									:placeholder="placeHolder"
									label="Pemegang Kartu Akses"
									:options="users"
									@change="userChange($event)"
								/>
							</CCol>
							<CCol sm="6">
								<CSelect 
									:value.sync="site_id"
									name="site_id"
									label="Nama Data Center"
									placeholder="Pilih Lokasi Data Center"
									:options="sites"
									@change="siteChange($event)"
								/>
							</CCol>
							<CCol sm="12">
								<input inline id="all" type="checkbox" @click="selectAll" v-model="allSelected">&nbsp;
								<label for="all"> <h6>Pilih {{allSelected ? 'Semua' : ''}} Ruangan</h6></label>
								<p v-if="rooms.length === 0">PILIH LOKASI DATA CENTER DAHULU.</p>
							</CCol>
							<CCol sm="6" v-for="item in rooms" :key="item.id">
								<input inline type="checkbox" :id="item.value" :value="item.value" v-model="allowing_access">&nbsp;
								<label :for="item.value"> {{item.label}}</label>
							</CCol>
						</CRow>
					</CCardBody>
					<CCardFooter>
						<CButton type="submit" size="sm" color="success" @click="id === 0 ? createCard() : updateCard()"><CIcon name="cil-check-circle"/> Save</CButton> &nbsp;&nbsp;
						<CButton type="reset" size="sm" color="danger" @click="resetForm()"><CIcon name="cil-ban"/> Clear</CButton> &nbsp;&nbsp;
						<CButton type="cancel" size="sm" color="primary" @click="goBack()"><CIcon name="cil-ban"/> Cancel</CButton>
					</CCardFooter>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'CardManagement',
	components: { TheTableHeader },
	data () {
		return {
			id: +this.$route.params.id,
			site_id: 1,
			company_id: 0,
			role_id: '',
			card_number: '',
			card_holder: 0,
			allSelected: false,
			allowing_access: [],
			forbidden_access: [0],
			sites: [],
			rooms: [],
			roles: [],
			companies: [],
			placeHolder: 'Pilih perusahaan',
			users: [],
			allowedRooms: [],
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Kartu Akses',
          href: '#/settings/cards'
        }, 
        {
          text: 'Manajemen Kartu Akses'
        }
      ],
		}
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goBack(){
			this.$router.go(-1);
    },
		getBadge (status) {
			return status === 'Active' ? 'success'
				: status === 'Inactive' ? 'secondary'
					: status === 'Pending' ? 'warning'
						: status === 'Banned' ? 'danger' : 'primary'
		},
		getSites() {
			const self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		siteChange(event) {
			// console.log(event.target.value)
			this.rooms = [];
			this.getRoomsBySite(event.target.value)
		},
		getRoomsBySite(id) {
			const self = this;
			return http.get('/rooms/site/' + id)
			.then(function (response) {
				const data = response.data.room;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['room_name'];
					items['value'] = data[i]['id'];
					self.rooms.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getCompanies (){
			const self = this;
			return http.get('/companies')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['company_name'];
					items['value'] = data[i]['id'];
					self.companies.push(items)
				}

			}).catch(function (error) {
				console.log(error);
			});
		},
		companyChange(event) {
			this.company_id = event.target.value;
			this.getUsers(event.target.value);
		},
		createCard() {
			let self = this;
			return http.post('/card', {
				site_id: self.site_id,
				card_number: self.card_number,
				card_holder: self.card_holder,
				allowing_access: self.allowing_access.toString(),
				forbidden_access: self.forbidden_access.toString()
			})
			.then(function (response) {
				self.toast('Kartu Akses Berhasil Ditambahkan.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		updateCard() {
			let self = this;
			return http.put('/card/' + self.id, {
				site_id: self.site_id,
				card_number: self.card_number,
				card_holder: self.card_holder,
				allowing_access: self.allowing_access.toString(),
				forbidden_access: self.forbidden_access.toString()
			})
			.then(function (response) {
				self.toast('Kartu Akses Berhasil Diubah.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		resetForm() {
			let self = this;
			self.site_id = 0;
			self.card_number = '';
			self.card_holder = 0;
			self.rooms = [];
			self.allowing_access = [];
			self.forbidden_access = [];
		},
		getRoles() {
			let self = this;
			return http.get('/roles')
			.then(function (response) {
				const data = response.data.roles;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['name'];
					items['value'] = data[i]['id'];
					self.roles.push(items)
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		userChange(event) {
			// console.log(this.role_id)
			let self = this;
			self.card_holder = event.target.value;
			// console.log(event.target.value)
		},
		getCard(id) {
			let self = this;
			if (+id > 0) {
				return http.get('/card/' + id)
				.then(function (response) {
					console.log('id', id)
					// console.log(response.data.data)
					const data = response.data.data[0];
					self.site_id = data.site_id;
					self.company_id = data.company_id;
					self.card_number = data.card_number;
					self.card_holder = data.card_holder;
					
					// self.getCompany(+data.card_holder);
					self.getRoomsBySite(data.site_id);
					self.allUsers();

					self.allowing_access = data.allowing_access.split(',');
					self.forbidden_access = data.forbidden_access.split(',');
				}).catch(function (error) {
					console.log(error);
				});
			} else {
				self.getRoomsBySite(1);
				self.getUsers(1);
			}
		},
		getUsers(id) {
			let self = this;
			self.placeHolder = 'Mencari..';
			self.users = [];
			return http.get('/users/company/' + id)
			.then(function (response) {
				const data = response.data.user;

				if (data.length > 0) {
					self.card_holder = data[0]['id'];
				}
				// console.log(data[0])
				
				if (data) {
					for (let i = 0; i < data.length; i++) {
						const items = {};
						items['label'] = data[i]['name'] + ' (' + data[i]['role'] + ')';
						items['value'] = data[i]['id'];

						// put only activated users into the list
						if (data[i]['status'] === 'Active' && data[i]['role'] !== 'Guest') {
							self.users.push(items);
						}
					}
					self.placeHolder = '';
				} else {
					self.placeHolder = 'Tidak ditemukan.';
				}
				
			}).catch(function (error) {
				console.log(error);
				self.placeHolder = 'Pencarian gagal.';
			});
		},
		selectAll() {
			let self = this;
			if (self.allSelected) {
				self.allowing_access = [];
			} else {
				self.rooms.forEach((room, index) => {
        	self.allowing_access.push(room.value.toString());
      	})
			}
		},
		allUsers() {
			let self = this;
			return http.get('/users')
				.then(function (response) {
					const data = response.data.users;
					for (let i = 0; i < data.length; i++) {
						const items = {};
						items['label'] = data[i]['name'] + ' (' + data[i]['role'] + ')';
						items['value'] = data[i]['id'];

						// put only activated users into the list
						if (data[i]['status'] === 'Active' && data[i]['role'] !== 'Guest') {
							self.users.push(items);
						}
					}
				}).catch(function (error) {
					console.log(error);
				});
		}
	},
	mounted: function() {
		this.getCard(self.id);
		this.getCompanies();
		this.getSites();
	}
}
</script>